import { useEffect, useState } from "react";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { RequestFilter } from "./components/RequestFilter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
	closeProfile,
	showFilter,
} from "../../../Redux/reducer/RequestReducer";
import {
	useDeleteContractorMutation,
	useUnpublishedContractorQuery,
} from "../../../Redux/Api/Contractor";
import { Contractor } from "../../../Redux/Api/types";
import { useNavigate } from "react-router-dom";
import { setSelectedCategories } from "../../../Redux/reducer/Filter";
import * as XLSX from "xlsx";
import { MdDelete } from "react-icons/md";

export const ContractorsRequest = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [search, setSearch] = useState<string>("");
	const [showModal, setShowModal] = useState<boolean>(false);
	const [remarkState, setRemarkState] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState<string | null>(null);
	const [limit, setLimit] = useState<string | number>("20");
	const [page, setPage] = useState(1);

	const { data, error, isLoading, refetch } = useUnpublishedContractorQuery({
		page,
		limit,
		search,
	});
	const [deleteContractorMutation, { data: deleteData }] =
		useDeleteContractorMutation();
	const [visibleComponent, setVisibleComponent] =
		useState<string>("contractors");

	const [selectedId, setSelectedId] = useState<string>("");
	const [comp, setComp] = useState<string | null>(null);

	const [deleteDataState, setDeleteDataState] = useState({
		id: "",
		name: "",
		remark: "",
	});
	const [validationMessage, setValidationMessage] = useState<string>("");

	const options = ["9", "8", "7", "All"];
	const Contractor = useSelector(
		(state: RootState) => state.admin.Contractor
	);

	const handleSelect = (option: string) => {
		setSelectedOption(option);
		setLimit(option === "All" ? "All" : parseInt(option));
	};

	const filterVisible = useSelector(
		(state: RootState) => state.request.isFilterVisible
	);

	const toggleFilter = (id: string, categoryProduct: string[]) => {
		dispatch(showFilter());
		setSelectedId(id);
		dispatch(setSelectedCategories(categoryProduct));
	};

	const toggleComponent = (component: string) => {
		setComp(component);
		setVisibleComponent(component);
		dispatch(closeProfile());
	};

	const contractor = Contractor?.data?.pendingContractor;

	useEffect(() => {
		refetch();
	}, [page, limit, search, refetch]);

	const handleDeleteContractorWithRemark = async () => {
		if (deleteDataState.remark.trim().split(/\s+/).length < 5) {
			setValidationMessage("Input must have at least 5 words.");
			return;
		}

		try {
			await deleteContractorMutation({
				id: deleteDataState.id,
				remark: deleteDataState.remark,
			}).unwrap();

			refetch();
			setShowModal(false);
			setRemarkState(false);
			setDeleteDataState({
				id: "",
				name: "",
				remark: "",
			});
		} catch (error) {
			console.error("Error deleting contractor:", error);
		}
	};

	const handleInputChange = (e: any) => {
		const value = e.target.value;
		setDeleteDataState((prev) => ({ ...prev, remark: value }));
		if (value.trim().split(/\s+/).length >= 5) {
			setValidationMessage("");
		}
	};

	const totalPages = data?.totalPages || 1;

	const prevPage = () => {
		if (page > 1) {
			setPage(page - 1);
		}
	};

	const nextPage = () => {
		if (page < totalPages) {
			setPage(page + 1);
		}
	};

	const exportToExcel = () => {
		// Create a worksheet and workbook
		const worksheet = XLSX.utils.json_to_sheet(data?.contractors || []);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Contractors");

		// Generate buffer and save
		const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
		const blob = new Blob([wbout], { type: "application/octet-stream" });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "contractors.xlsx";
		link.click();
		window.URL.revokeObjectURL(url);
	};

	const startItem =
		(page - 1) * (typeof limit === "number" ? limit : parseInt(limit)) + 1;
	const endItem = Math.min(
		page * (typeof limit === "number" ? limit : parseInt(limit)),
		contractor || 0
	);
	const resetAll = () => {
		setSearch("");
		setPage(1);
		setLimit("20");
	};

	return (
		<>
			<div className="w-full h-auto px-3 mt-3 pb-3 ">
				<div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
					<div className="flex justify-between py-2 px-4">
						<div className="flex items-center gap-2">
							<input
								type="text"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-[0.5px]"
								placeholder="Search.."
								style={{
									boxShadow:
										"0px 0px 7.6px 0px #00000040 inset",
								}}
							/>
						</div>
						<div className="flex gap-2">
							<button
								onClick={resetAll}
								className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
								Reset
							</button>
							<button
								onClick={exportToExcel}
								className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
								Excel{" "}
								<RiFileExcel2Fill className="w-[20px] h-[20px]" />
							</button>
						</div>
					</div>
					<div className="w-full overflow-x-auto">
						<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative ">
							<thead className="bg-white divide-y divide-gray-200">
								<tr className="border-t">
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Name
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Contact
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Contractor Type
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										City
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										GST{" "}
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Action
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Status{" "}
									</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								{data?.contractors?.map(
									(i: Contractor, index: number) => (
										<tr key={index}>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] cursor-pointer">
												{i?.name || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.phoneNumber || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.contractorType || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.city || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.gst || "-"}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												<span
													className="text-blue-600 bg-blue-100  border-[1px] border-blue-600 text-xs font-medium me-2 px-2.5 py-[6px]  rounded-xl  cursor-pointer hover:text-white hover:bg-blue-600 duration-200"
													onClick={() =>
														toggleFilter(
															i?._id,
															i?.categoryProduct
														)
													}>
													Action
												</span>
											</td>
											<td className="px-6 py-1 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.isVerified ? (
													<div className="text-white bg-green-500 text-xs font-medium me-2 px-2.5 py-[6px] rounded-xl">
														Approved
													</div>
												) : (
													<div className="text-white bg-orange-500 text-xs font-medium me-2 px-2.5 py-[6px] rounded-xl">
														Pending
													</div>
												)}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-xl  w-[10%] cursor-pointer text-red-600">
												<span
													onClick={() => {
														setDeleteDataState(
															(prev) => ({
																...prev,
																id: i?._id,
																name: i?.name,
															})
														);

														setShowModal(true);
													}}>
													<MdDelete />
												</span>
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
					{/* Pagination */}
					<div className="flex flex-row justify-between p-3 m-1 border-t-2 ">
						<div>
							<div className="flex flex-row gap-3 ">
								<Dropdown
									options={options}
									onSelect={handleSelect}
								/>
								<p className="font-semibold">
									Showing{" "}
									{limit === "All"
										? "All"
										: `${startItem} - ${
												endItem || ""
										  }`}{" "}
									Contractors Request
								</p>
								<p className="text-[#A6A8B1] ">
									Contractors Request
								</p>
							</div>
						</div>

						<div>
							<div className="flex flex-row gap-3">
								<p className="text-[#A6A8B1]">
									{page}of {totalPages} pages
								</p>
								<div className="flex flex-row items-center gap-2 text-[#666666]">
									<span
										onClick={prevPage}
										className={`cursor-pointer ${
											page === 1 ? "opacity-50" : ""
										}`}>
										<FaChevronLeft size={15} />
									</span>
									<span
										onClick={nextPage}
										className={`cursor-pointer ${
											page === totalPages
												? "opacity-50"
												: ""
										}`}>
										<FaChevronRight size={15} />
									</span>
								</div>
							</div>
						</div>
					</div>
					{showModal && (
						<div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
							<div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
								<div className="mt-3 text-center space-y-5">
									{!remarkState && (
										<h3 className="text-lg leading-6 font-medium text-red-600">
											Do You want to delete{" "}
											<span className="text-blue-600">
												{deleteDataState.name}?
											</span>
										</h3>
									)}
									{remarkState && (
										<span className="space-x-2">
											<label>Remark</label>
											<input
												className="border-2 rounded-[4px]"
												onChange={handleInputChange}
											/>
											{validationMessage && (
												<p className="text-red-600">
													{validationMessage}
												</p>
											)}
										</span>
									)}

									<span className="flex gap-3 justify-center">
										{!remarkState && (
											<button
												className="p-2 rounded-[6px] bg-blue-600  hover:bg-blue-300 hover:text-black text-white"
												onClick={() => {
													setRemarkState(true);
												}}>
												Yes
											</button>
										)}
										{remarkState && (
											<button
												className="p-2 rounded-[6px] bg-red-600  hover:bg-red-300 hover:text-black text-white"
												onClick={
													handleDeleteContractorWithRemark
												}>
												Yes
											</button>
										)}

										<button
											className="p-2 rounded-[6px] bg-gray-600 hover:bg-gray-300 hover:text-black text-white"
											onClick={() => {
												setShowModal(false);
												setRemarkState(false);
											}}>
											No
										</button>
									</span>
								</div>
							</div>
						</div>
					)}

					{filterVisible && <RequestFilter id={selectedId} />}
				</div>
			</div>
		</>
	);
};
