import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useGetContractorsMutation } from "../../../../Redux/Api/Admin/Dashboard";
import { exportToExcel } from "../../../../utils/exportToExcel";
import { toast } from "react-toastify";

interface Data {
  ContractorJoined: number;
  date: string;
  TotalContractor?: number;
}

const BarChartComponent = () => {
  const [body, setBody] = useState({
    startDate: "",
    endDate: "",
  });

  const [ContractorData, setContractorData] = useState<Data[]>([]);
  const [totalContractor, setTotalContractor] = useState<number>();

  const [contractorList, setContractorList] = useState<any>(false);

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthIndex = currentDate.getMonth();

    const startDate = new Date(currentYear, currentMonthIndex, 1);
    const endDate = new Date(currentYear, currentMonthIndex + 1, 0);

    const formattedStartDate = `${startDate.getFullYear()}-${String(
      startDate.getMonth() + 1
    ).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`;

    const formattedEndDate = `${endDate.getFullYear()}-${String(
      endDate.getMonth() + 1
    ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;

    return { formattedStartDate, formattedEndDate };
  };

  useEffect(() => {
    const { formattedStartDate, formattedEndDate } = getCurrentMonthDates();
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    setBody({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  }, []);

  const [ContractorMutation] = useGetContractorsMutation();

  useEffect(() => {
    if (body.startDate && body.endDate) {
      ContractorMutation(body).then((result: any) => {
        if (result?.data?.monthData) {
          console.log("ContractorMutation", result.data.monthData);
          setContractorData(result.data.monthData);
          setTotalContractor(result.data.totalContractorTillDate);
          setContractorList(result.data.contractorList);
        }
      });
    }
  }, [ContractorMutation, body]);

  const dayFormatter = (dateString: string) => {
    const date = new Date(dateString);
    return String(date.getDate()).padStart(2, "0");
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const applyDateRange = () => {
    setBody({
      startDate,
      endDate,
    });
  };

  const HandleExportToExcel = () => {
    if (startDate && endDate) {
      const data = contractorList?.map((item: any, index: number) => {
        return {
          "SN": index + 1,
          "Contractor Name": item?.name,
          "Date": item?.dateOfCreation?.split("T")[0],
          "Time": item?.dateOfCreation?.split("T")[1]?.split(".")[0]
        };
      });

      exportToExcel(data, `New Contractor join data`);
    } else {
      toast.error("Please select both StartDate and EndDate to export data !", { position: "top-center" })
    }
  };


  return (
    <div className="bg-white p-4 rounded-lg shadow-sm">
      <div className="flex gap-3 items-center">
        <h1 className="font-bold text-xl">Contractors Chart</h1>
        <span className="text-red-600 font-bold">
          Total Joined Contractor :
        </span>
        <span className="text-green-500 font-bold flex">
          <span>{totalContractor}</span>
          <span></span>
        </span>
      </div>

      <div className="mb-4">
        <label className="mr-2 font-semibold">Start Date:</label>
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          className="border p-2 rounded mr-4"
        />
        <label className="mr-2 font-semibold">End Date:</label>
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          className="border p-2 rounded"
        />
        <button
          onClick={applyDateRange}
          className="bg-blue-500 text-white p-2 ml-4 rounded"
        >
          Apply
        </button>
        <button
              onClick={HandleExportToExcel}
              className="text-sm  bg-green-100 rounded-md ml-2 p-2 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
              Export
            </button>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={ContractorData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={dayFormatter} /> <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="step"
            dataKey="contractorJoined"
             stroke="#ff0000"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="totalContractor"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>

      <div>
        <div className="flex gap-2 items-center">
          <span className="text-xl font-bold ">Contractor List</span>
          <span className="font-bold text-blue-700">
            {`( ${body.startDate} & ${body.endDate} )`}
          </span>
    
        </div>
        <div className="bg-gray-50 w-full p-5 rounded-lg shadow-md">
          <div
            className={`${contractorList.length > 5
              ? "h-[225px] overflow-y-auto"
              : `h-[${65 * contractorList.length}px]`
              }`}
          >
            <table className="w-full text-left table-auto border-separate border-spacing-y-2">
              <thead className="bg-teal-600 text-white rounded-md">
                <tr>
                  <th className="px-4 py-3 sticky top-0 z-10">Serial No</th>
                  <th className="px-4 py-3 sticky top-0 z-10">Name</th>
                  <th className="px-4 py-3 sticky top-0 z-10">Date</th>
                  <th className="px-4 py-3 sticky top-0 z-10">Time</th>
                </tr>
              </thead>
              <motion.tbody
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {contractorList &&
                  contractorList.map((item: any, index: number) => (
                    <motion.tr
                      key={index}
                      className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"
                        } border-b border-gray-200 hover:bg-blue-50 transition-all duration-200`}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4, delay: index * 0.1 }}
                    >
                      <td className="px-4 py-2 text-gray-700">{index + 1}</td>
                      <td className="px-4 py-2 text-gray-700">{item?.name}</td>
                      <td className="px-4 py-2 text-gray-700">
                        {item?.dateOfCreation?.split("T")[0]}
                      </td>
                      <td className="px-4 py-2 text-gray-700">
                        {item?.dateOfCreation?.split("T")[1]?.split(".")[0]}
                      </td>
                    </motion.tr>
                  ))}
              </motion.tbody>
            </table>
          </div>
        </div>


      </div>
    </div>
  );
};

export default BarChartComponent;
