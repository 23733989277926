import { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { CiFilter } from "react-icons/ci";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";

export const RequestReward = () => {
	const options = ["9", "8", "7"];

	return (
		<>
			<div className="w-full h-auto px-3 mt-3 pb-3">
				<div className="w-full   bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
					<div className="flex justify-between py-2 px-4">
						<div className="flex items-center gap-2">
							<input
								type="text"
								// onChange={(e) => setName(e.target.value)}
								// value={name}
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
								placeholder="Search.."
								style={{
									boxShadow:
										"0px 0px 7.6px 0px #00000040 inset",
								}}
							/>
						</div>
						<div className="flex gap-2">
							<button
								// onClick={resetAll}
								className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
								Reset
							</button>
							<button
								// onClick={handleShowCoupon}
								className="flex gap-1 items-center text-sm py-2 px-3 border border-blue-700 text-blue-700 rounded-xl bg-blue-100 hover:text-white  hover:bg-blue-700 duration-150 ">
								Add Coupons
							</button>
							<button
								// onClick={exportToExcel}
								className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
								Excel{" "}
								<RiFileExcel2Fill className="w-[20px] h-[20px]" />
							</button>
						</div>
					</div>

					<div className="w-full overflow-x-auto">
						<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
							<thead className="bg-white divide-y divide-gray-200">
								<tr className="border-t">
								
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Name
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Contact
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Contractor Type
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										City
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										GST
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Grade
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Action
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Status
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Coupon type
									</th>
								</tr>
							</thead>
							<tbody className="bg-white">
								<tr>
									<td
										colSpan={9}
										className="py-4 flex justify-center items-center text-lg font-medium text-red-500">
										<div>Padding Work</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					{/* <div className='flex flex-row justify-between p-2 m-1 border-t-2 '>
            <div>
              <div className='flex flex-row gap-3'>
                <Dropdown options={options} onSelect={handleSelect} />
                <p className='text-[#A6A8B1]'>Items Per Page</p>
                <p>Categories</p>
              </div>
            </div>

            <div>
              <div className='flex flex-row gap-3'>
                <Dropdown options={options} onSelect={handleSelect} />
                <p className='text-[#A6A8B1]'>of 44 pages</p>
                <div className='flex flex-row items-center gap-2 text-[#666666]'>
                  <span >
                    <FaChevronLeft size={15} />
                  </span>

                  <span >
                    <FaChevronRight size={15} />
                  </span>
                </div>
              </div>
            </div>
          </div> */}
				</div>
			</div>
		</>
	);
};
