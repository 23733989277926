import { useGetOrdersDetailsMutation, useGetReferralOrdersMutation, } from "../../../Redux/Api/Order";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import OrderTabs from "./components/OrderTabs";

const Orders = () => {
  const location = useLocation();
  const [mutation] = useGetReferralOrdersMutation();
  const [mutationData, { data: orderData }] = useGetOrdersDetailsMutation();

  useEffect(() => {
    const body = { referralOrder: false };
    mutation(body);
    mutationData();
  }, [mutation]);

  const hideTabs =
    (location.pathname.includes("/complete/") || location.pathname.includes("/request/")) &&
    location.pathname.split("/").length > 2;
  return (
    <div className="flex flex-col gap-[10px] bg-slate-100">
      <div className="w-full bg-white p-[10px] font-[600] text-[24px] leading-[28px]">
        Referral Orders
      </div>

      <div className="mx-3 pb-3">
        {
          !hideTabs &&
          < OrderTabs />
        }
        <div className={`w-full flex flex-col md:flex-row justify-around sm:justify-start sm:gap-x-10 sm:pl-3 h-auto rounded-xl  p-4 bg-white ${hideTabs ? " hidden" : ""}`}
          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
          <div className="flex flex-col justify-center h-full font-medium items-center gap-2 md:pl-2">
            <div className="text-[#8B8D97]">Total Requests</div>
            <div className="text-[#F59E0B]">
              {orderData?.data?.totalReferralOrder}
            </div>
          </div>

          <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
            <div className="text-[#8B8D97]">Complete Order</div>
            <div className="text-[#F59E0B]">
              {orderData?.data?.completeOrders}
            </div>
          </div>
        </div>
        <div className={`w-full h-auto  mt-3 pb-3 pt-2  rounded-t-xl ${hideTabs ? " bg-none" : "bg-white"}`}>

          <Outlet />
        </div>


      </div>


    </div>
  );
};

export default Orders;
