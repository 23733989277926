import { FaStar } from "react-icons/fa6";
import { FaStarHalfStroke } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import icon from "../../../assets/Icon.png";
import whatsapp from "../../../assets/whatsappicon.svg";
import sheild from "../../../assets/Shield Done.png";
import item from "../../../assets/item.png";
import Shield from "../../../assets/Shield.png";

interface CategoryRewardCardProps {
  product: {
    id: string;
    productName: string;
    finalPrice: number;
    rewards: number;
    status: boolean;
    unit: string;
    images: string;
  };
}

const CategoryRewardCard: React.FC<CategoryRewardCardProps> = ({ product }) => {
  const navigate = useNavigate();

  const handleWhatsappClick = () => {
    const phoneNumber = "9258294692";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <div className="flex gap-[2rem] py-[8px] border-t border-b w-full">
      <div className="flex gap-[2rem] py-[15px] w-full">
        <div
          onClick={() =>
            navigate(`/singleproduct/${product.id}`, { state: { id: product.id } })
          }
          className="cursor-pointer relative w-[170px] h-[170px] md:w-[197px] md:h-[197px] rounded-[4px] overflow-hidden"
        >
          <img
            src={product.images || item}
            alt="item"
            className="w-[190px] h-full object-cover"
          />
          <div className=" hidden sm:flex absolute top-0 w-full left-0 z-20 p-2 rounded-br-[200px] items-center bg-white gap-[10px]">
            <img src={sheild} alt="sheild" className="w-[13px] h-[15px] object-cover" />
            <div className="flex whitespace-nowrap overflow-hidden text-green-700 text-[14px] font-[400]">
              Lohawalla Assured
            </div>
          </div>

          <div className=" flex sm:hidden w-[50px] h-[50px] border-[3px]  z-10 border-[#24FF00] absolute bottom-1 right-1 rounded-full bg-green-100 lg:bg-[#33FF0033]   flex-col items-center justify-center gap-1">
            <img
              src={Shield}
              alt="Shield Icon"
              className="w-3 h-3"
            />
            <div className="text-[6.5px] text-wrap text-center font-medium text-[#047857]">
              Lohawalla Assured
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-[8px]">
            <div
              className="font-[500] text-[12px] sm:text-[18px] leading-[21px] cursor-pointer"
              onClick={() =>
                navigate(`/singleproduct/${product.id}`, { state: { id: product.id } })
              }
            >
              {product.productName}
            </div>

            <div className="flex flex-col gap-[8px]">
              <div className="font-[500] text-[14px] leading-[17px] flex items-center gap-[9px]">
                {product.finalPrice
                  ? `₹${product.finalPrice.toFixed(2)}/${product.unit}`
                  : "Price not available"}
              </div>

              <div className="font-400 text-[10px] sm:text-[14px] leading-[22px] text-blue-400 flex items-center gap-2">
                <span>(For Best Rate, Please Contact Us)</span>
                <img
                  src={whatsapp}
                  className="w-[22px] h-[22px] cursor-pointer"
                  onClick={handleWhatsappClick}
                  alt="whatsapp"
                />
              </div>
            </div>

            {product.rewards ? (
              <div className="flex  flex-col items-start sm:flex-row sm:items-center">
                <span className="text-[15px] pb-1 sm:pb-0">You will earn{" "}</span>
                <div className=" flex items-center">
                  <img className="mr-2 sm:mx-2" src={icon} alt="icon" />{" "}
                  <span className="text-[15px] sm:text-[20px] mr-2 text-nowrap">{product.rewards} super coins</span>

                </div>
              </div>
            ) : null}

            <div className="flex text-center text-[#007185] items-center text-[10px]">
              <div className="flex pr-[12px] font-[400] text-[10px] sm:text-[14px] leading-[16px] text-[#007185]">
                <FaStar className="text-yellow-600" />
                <FaStar className="text-yellow-600" />
                <FaStar className="text-yellow-600" />
                <FaStar className="text-yellow-600" />
                <FaStarHalfStroke className="text-yellow-600" />
              </div>
              <IoIosArrowDown className="text-black" />
              <span className="text-[10px] sm:text-[14px]">1,084</span>
            </div>
          </div>

          {product.status ? (
            <button className="flex w-fit h-fit gap-2 px-[8px] py-[6px] p-1 rounded-[6px] bg-[#65A30D] text-[10px] sm:text-[14px] font-semibold text-white">
              Active
            </button>
          ) : (
            <button className="flex w-fit h-fit gap-2 px-[8px] py-[6px] p-1 rounded-[6px] bg-red-500 text-[10px] sm:text-[14px] font-semibold text-white">
              InActive
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryRewardCard;
