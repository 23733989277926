import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useDashboardWebsiteVistMutation } from "../../../../Redux/Api/Contractor";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { exportToExcel } from "../../../../utils/exportToExcel";
import { toast } from "react-toastify";

interface VisitData {
  [key: string]: number;
}

interface SelectDate {
  year: string;
  month: string;
  comparisonMonth: string;
}

export function VisitChartComponent() {
  const currentYear = new Date().getFullYear();
  const years = [
    "All",
    ...Array.from({ length: 6 }, (_, i) => currentYear - i),
  ];
  const currentMonth = new Date().toLocaleString("default", {
    month: "long",
  });

  const [currentWebsiteUser, setWebsiteUser] = useState();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //   const currentMonthIndex = months.indexOf(currentMonth);
  // const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
  // const previousMonth = months[previousMonthIndex];

  const [selectDate, setSelectDate] = useState<SelectDate>({
    year: currentYear.toString(),
    month: currentMonth,
    comparisonMonth: "",
  });

  const [chartData, setChartData] = useState<(string | number)[][]>([
    ["Time", "Visit Count", "monthCount"],
  ]);

  const [mutationData, { error: errorData, data: apiData }] =
    useDashboardWebsiteVistMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("selectDate", selectDate);
        const data = await mutationData(selectDate);

        if (data && data.data) {
          let formattedData: (string | number)[][] = [
            ["Time", "Visit Count"],
          ];

          if (selectDate.year === "All") {
            formattedData = [["Year", ""]];
            data.data.data.forEach((item: VisitData) => {
              const [year, visitCount] = Object.entries(item)[0];
              formattedData.push([year, visitCount]);
            });
         
            setWebsiteUser(data.data.totalCount);
          } else if (selectDate.month === "") {
            formattedData = [["Month", ""]];
            data.data.data.forEach((item: VisitData) => {
              const [month, visitCount] = Object.entries(item)[0];
              formattedData.push([month, visitCount]);
            });
            setWebsiteUser(data.data.totalCount);
          } else if (selectDate.comparisonMonth === "") {
            formattedData = [["Date", ""]];
            data.data.data.forEach((item: VisitData) => {
              const [date, visitCount] = Object.entries(item)[0];
              formattedData.push([date, visitCount]);
            });
            setWebsiteUser(data.data.totalCount);
          } else {
            formattedData = [
              [
                "Date",
                `${selectDate.month}, ${data?.data?.firstMonth} `,
                `${selectDate.comparisonMonth}, ${data?.data?.comparisonMonth}`,
              ],
            ];
            data.data.data.forEach((item: VisitData) => {
              const [date, month1, month2] = Object.values(item);
              formattedData.push([
                date || "",
                month1 || 0,
                month2 || 0,
              ]);
            });

            setWebsiteUser(data?.data?.totalCount);
          }

          // console.log("formattedData", formattedData);
          setChartData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectDate, mutationData]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = event.target.value;
    setSelectDate({
      year: selectedYear,
      month: selectedYear === "All" ? "" : selectDate.month,
      comparisonMonth: "",
    });
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = event.target.value;
    setSelectDate({
      ...selectDate,
      month: selectedMonth,
      comparisonMonth:
        selectedMonth === "" ? "" : selectDate.comparisonMonth,
    });
  };

  const handleComparisonMonthChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedComparisonMonth = event.target.value;
    setSelectDate({
      ...selectDate,
      comparisonMonth: selectedComparisonMonth,
    });
  };


  const HandleExportToExcel = () => {
    if (selectDate.year && selectDate.month) {
      const data = chartData.slice(1).map((item, index) => {
        return {
          SN: index + 1,
          Date: `${item[0]}-${selectDate.year}`, 
          ViewCount: item[1],
        };
      });

      exportToExcel(data, `Website Count Data`);
    } else {
      toast.error("Please select both year and month to export data !", { position: "top-center" })
    }
  };

  return (
    <>
      <div className="flex gap-3 items-center">
        <span className="font-bold text-xl">WEBSITE VIEWERS</span>
        <span className="text-red-600 font-bold">Total Viewers :</span>

        <span className="text-green-500 font-bold flex">
          <span>{currentWebsiteUser}</span>
          <span>
            <HiMiniArrowTrendingUp className="text-2xl text-red-700" />
          </span>
        </span>
        <button onClick={HandleExportToExcel} className="text-sm bg-green-100 rounded-md ml-auto py-1  px-3 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
          Export
        </button>
      </div>
      <div className="flex my-3 space-x-4">
        <div>
          <label htmlFor="year" className="font-bold">
            Select Year:
          </label>
          <select
            id="year"
            value={selectDate.year}
            onChange={handleYearChange}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {selectDate.year !== "All" && (
          <div>
            <label htmlFor="month" className="font-bold">
              Select Month:
            </label>
            <select
              id="month"
              value={selectDate.month}
              onChange={handleMonthChange}>
              <option value="">All Months</option>

              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectDate.year !== "All" && selectDate.month !== "" && (
          <div>
            <label htmlFor="month" className="font-bold">
              Comp Month:
            </label>
            <select
              id="month"
              value={selectDate.comparisonMonth}
              onChange={handleComparisonMonthChange}>
              <option value="">Select month</option>
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <Chart
        chartType="Bar"
        width="95%"
        height="400px"
        data={chartData}
      />
    </>
  );
}
