import { BiSolidCoinStack } from "react-icons/bi";
import { TbLogout, TbPencilMinus } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
  useChangeEmailAndNameMutation,
  useContractorChangePasswordMutation,
  useLogoutApiMutation,
} from "../../../Redux/Api/Contractor";
import { LoginResponse } from "../../../Redux/reducer/LoginReducer";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { toPng } from "html-to-image";
import { IoMdShare } from "react-icons/io";
import { toast } from "react-toastify";

export const MyProfile = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );
  const navigate = useNavigate();
  const [state, setState] = useState({
    changePasswordLog: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    passwordsMatch: true,
  });

  const [changeData, setChangeData] = useState({
    changeName: false,
    changeEmail: false,
    email: "",
    name: "",
  });

  const [mutation, { data: changePasswordResponse, error }] =
    useContractorChangePasswordMutation();
  const [mutationData, { error: errorData }] = useChangeEmailAndNameMutation()


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    if (error) {
      toast.warn(error?.data?.message);
    }
    if (changePasswordResponse) {
      toast.success(changePasswordResponse?.message);
      // console.log(changePasswordResponse.message)
    }
  }, [mutation, changePasswordResponse, error]);

  const handlePasswordChange = async () => {
    if (state.newPassword !== state.confirmPassword) {
      setState({ ...state, passwordsMatch: false });
      return;
    }

    // Implement your logic to handle password change
    const body = {
      oldPassword: state.oldPassword,
      newPassword: state.newPassword,
    };
    mutation(body);

    setState({
      changePasswordLog: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      passwordsMatch: true,
    });
  };

  const qrRef = useRef<HTMLDivElement>(null);

  const [LogoutCall, { data: logoutData }] = useLogoutApiMutation();

  const handleLogout = async () => {
    const result = await LogoutCall().unwrap();
    if (result?.success) {
      dispatch(LoginResponse(null));
      navigate("/login"); // Redirect to login page after logout
    }
  };

  useEffect(() => {
    if (logoutData && logoutData.success) {
      dispatch(LoginResponse(null));
      navigate("/login"); // Redirect to login page after logout
    }
  }, [logoutData, dispatch, navigate]);

  const referralCode = userDetails?.referralCode || "ihuiu";
  const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
    `Use my referral code: ${referralCode}`
  )}`;

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.com$/;
    return emailRegex.test(email);
  };

  const handleNameEmailChange = async () => {
    let isValid = true;

    if (changeData.email.length > 0) {
      const isEmailValid = validateEmail(changeData.email);
      if (!isEmailValid) {
        showToastWarning("Email is incorrect.");
        isValid = false;
      }
    }

    if (isValid) {
      try {
        if (changeData.email.length > 0) {
          await mutationData({ email: changeData.email }).unwrap();
          toast.success("Email updated successfully");
        }

        if (changeData.name.length > 0) {
          await mutationData({ name: changeData.name }).unwrap();
          toast.success("Name updated successfully");
        }

        // Reset the changeData state after a successful update
        setChangeData({
          changeName: false,
          changeEmail: false,
          email: "",
          name: "",
        });
      } catch (error) {
        // Handle mutation errors here
        if (errorData) {
          showToastWarning(errorData?.data?.message || "Something went wrong");
        }
      }
    }
  };

  const handleShareToWhatsApp = async () => {
    if (qrRef.current) {
      const dataUrl = await toPng(qrRef.current);
      const base64Image = dataUrl.split(",")[1];
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
        `Use my referral code: ${referralCode}`
      )}&url=${encodeURIComponent(dataUrl)}`;
      window.open(whatsappUrl, "_blank");
    }
  };

  const role = userDetails?.role;

  return (
    <div>
      <div className=" w-full">
        <div className="flex justify-between p-2 border-b">
          <div className="flex justify-between items-center  gap-5">
            <span>Profile page </span>
            {role === "admin" && (
              <button
                className="hidden md:flex p-1 px-4 border rounded-lg bg-slate-200 border-gray-300 text-gray-800 hover:bg-slate-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
                onClick={() => {
                  window.location.reload(); // This will refresh the page
                  navigate("/admin");
                }}
              >
                Go to Admin Panel
              </button>
            )}
          </div>
          <div className="border rounded-[20px] flex justify-center items-center bg-gray-100 p-2 gap-[4px]">
            <Link to="/coin">
              <div className="flex p-1 border-[3px] bg-gray-200 rounded-[20px] justify-between items-center gap-2">
                <BiSolidCoinStack className="text-[#FF9900]" />
                <span>{userDetails?.totalReward}</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="sm:w-[40%] border-t flex flex-col mx-[20px] sm:ml-[20px] my-[50px] gap-[70px]">
          <div>
            <div className="flex justify-between py-[24px] border-b">
              <div className="w-[200px] flex-wrap">Full Name</div>
              <div className="flex-start w-full ">
                <span className="flex gap-6">
                  {changeData.changeName ? (
                    <input
                      className="border-green-700 rounded-lg border-2"
                      onChange={(e) =>
                        setChangeData({
                          ...changeData,
                          name: e.target.value,
                          email: "",
                        })
                      }
                    />
                  ) : (
                    userDetails?.name
                  )}
                  {changeData.changeName ? (
                    <span className="flex gap-1">
                      <button className="bg-blue-500 text-white rounded-[3px] p-1"
                        onClick={handleNameEmailChange}
                      >
                        Save
                      </button>
                      <button
                        className="bg-red-500 text-white rounded-[3px] p-1"
                        onClick={() =>
                          setChangeData((prev) => ({
                            ...prev,
                            changeName: false,
                          }))
                        }
                      >
                        Cancel
                      </button>
                    </span>
                  ) : (
                    <TbPencilMinus
                      className=" mt-1  cursor-pointer"
                      onClick={() => {
                        setChangeData((prev) => ({
                          ...prev,
                          changeName: true,
                          changeEmail: false,
                        }));
                        setState({ ...state, changePasswordLog: false });
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="flex justify-between py-[24px] border-b">
              <div className="w-[200px] flex-wrap">Phone number</div>
              <div className="flex-start w-full pl-4">
                {userDetails?.phoneNumber}
              </div>
            </div>
            <div className="flex justify-between py-[24px] border-b">
              <div className="w-[200px] flex-wrap">Email address</div>
              <div className="flex-start w-full pl-4">
                <span className="flex items-center gap-6">
                  <span>
                    {changeData.changeEmail ? (
                      <input
                        className="border-green-700 rounded-lg border-2"
                        onChange={(e) =>
                          setChangeData({
                            ...changeData,
                            email: e.target.value,
                            name: "",
                          })
                        }
                      />
                    ) : (
                      userDetails?.email
                    )}
                  </span>
                  {changeData.changeEmail ? (
                    <span className="flex gap-1">
                      <button className="bg-blue-500 text-white rounded-[3px] p-1"
                        onClick={handleNameEmailChange}
                      >
                        Save

                      </button>
                      <button
                        className="bg-red-500 text-white rounded-[3px] p-1"
                        onClick={() =>
                          setChangeData((prev) => ({
                            ...prev,
                            changeEmail: false,
                          }))
                        }
                      >
                        Cancel
                      </button>
                    </span>
                  ) : (
                    <TbPencilMinus
                      className=" mt-1  cursor-pointer"
                      onClick={() => {
                        setChangeData((prev) => ({
                          ...prev,
                          changeEmail: true,
                          changeName: false,
                        }));
                        setState({ ...state, changePasswordLog: false });
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="flex justify-between py-[24px] border-b">
              <div className="w-[200px] flex-wrap">Referral Code</div>
              <div className="flex-start w-full " ref={qrRef}>
                <QRCode value={referralCode} size={128} />
                <div className="flex-start w-full pl-4">{referralCode}</div>

                {/* <div className="flex justify-between py-[24px] border-b"> */}
                <div className="flex w-full gap-2 ">
                  <span
                    className="py-2 text-4xl cursor-pointer"
                    onClick={handleShareToWhatsApp}
                  >
                    <IoMdShare />
                  </span>
                  <button
                    onClick={handleShareToWhatsApp}
                    className="text-green-600"
                  >
                    Share QR Code on WhatsApp
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="flex justify-between py-[24px] border-b">
              <div className="w-[200px] flex-wrap">Password</div>
              {!state.changePasswordLog ? (
                <div className="flex w-full pl-4 flex-start justify-between">
                  <div>**************</div>
                  <div
                    className="text-[#4B4DED] cursor-pointer"
                    onClick={() => {
                      setState({ ...state, changePasswordLog: true });
                      setChangeData((prev) => ({
                        ...prev,
                        changeEmail: false,
                        changeName: false,
                      }));
                    }}
                  >
                    Change Password
                  </div>
                </div>
              ) : (
                <div className="flex w-full pl-4 flex-start justify-between">
                  <div className="flex flex-col gap-6">
                    <input
                      className="border-2 border-gray-600 rounded-[6px] p-1"
                      type="password"
                      placeholder="Enter Your Old Password"
                      name="oldPassword"
                      value={state.oldPassword}
                      onChange={handleChange}
                    />
                    <input
                      className="border-2 border-gray-600 rounded-[6px] p-1"
                      type="password"
                      placeholder="Enter Your New Password"
                      name="newPassword"
                      value={state.newPassword}
                      onChange={handleChange}
                    />
                    <input
                      className="border-2 border-gray-600 rounded-[6px] p-1"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={state.confirmPassword}
                      onChange={handleChange}
                    />
                    {!state.passwordsMatch && (
                      <p className="text-red-500">Passwords do not match.</p>
                    )}

                    <div className="flex gap-2">
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                        onClick={handlePasswordChange}
                      >
                        Save Password
                      </button>
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                        onClick={() =>
                          setState({ ...state, changePasswordLog: false })
                        }
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="w-[90px]">
            <span
              className="flex text-[#4B4DED] justify-center items-center cursor-pointer"
              onClick={handleLogout}
            >
              <TbLogout />
              Logout
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};


const showToastWarning = (message: string) => {
  toast.warn(message, {
    className: "bg-white text-[#5097A4]",
    autoClose: 2000,
  });
};