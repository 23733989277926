import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";

export const OrderQuery: any = api.injectEndpoints({
	endpoints: (builder) => ({
		OrderTracker: builder.mutation<any, string>({
			query: (orderNumber) => {
				//  console.log("items added",bodyData)
				return {
					url: `${lhwpath}/global/track?orderNumber=${orderNumber}`,
					method: "GET",
				};
			},
		}),
		getReferralOrders: builder.mutation<any, any>({
			query: (body) => {
				// console.log("order body", body);  // This should log the body received by the query function
				return {
					url: `${lhwpath}/global/orders`,
					method: "POST",
					body,
				};
			},
		}),

		postOrderHistory: builder.mutation<any, any>({
			query: (body) => {
				return {
					url: `${lhwpath}/global/orders/add/history`,
					method: "POST",
					body,
				};
			},
			invalidatesTags: ["Contractor"],
		}),

		getOrderDetails: builder.mutation<any, any>({
			query: (id) => {
				// console.log("order id details", id);  // This should log the body received by the query function
				return {
					url: `${lhwpath}/global/orders/${id}`,
					method: "GET",
				};
			},
		}),
		getAdminContractorHistory: builder.mutation<any, any>({
			query: (id) => {
				return {
					url: `${lhwpath}/global/orders/admin/contractor/data/${id}`,
					method: "GET",
				};
			},
		}),
		getOrdersDetails: builder.mutation<any, any>({
			query: () => {
				// console.log("order id details", id);  // This should log the body received by the query function
				return {
					url: `${lhwpath}/global/orders/admin/data`,
					method: "GET",
				};
			},
		}),
		getAdminSingleContractorMessage: builder.mutation<any, any>({
			query: (id) => {
				return {
					url: `${lhwpath}/global/contractor/admin/message/${id}`,
					method: "GET",
				};
			},
		}),

		getAdminAllContractorMessage: builder.query<any, { filter?: string; date?: string }>({
			query: ({ filter, date }) => {
				const params = new URLSearchParams();
				if (filter) {
					params.append("filter", filter);
				}
				if (date) {
					params.append("date", date);
				}
				return {
					// url: `${lhwpath}/global/contractor/admin/message`,
					url: `/global/contractor/admin/message${
						params.toString() ? `?${params.toString()}` : ""
					}`,
					method: "GET",
				};
			},
			providesTags: ["Contractor"],
		}),
		getDeleteAdminContractor: builder.query<any, any>({
			query: () => {
				return {
					url: `${lhwpath}/global/contractor/delete/history`,
					method: "GET",
				};
			},
		}),

		getUnblockAdminContractor: builder.mutation<any, any>({
			query: (id) => {
				return {
					url: `${lhwpath}/global/contractor/${id}`,
					method: "PATCH",
				};
			},
			invalidatesTags: ["Contractor"],
		}),

		getContractorCoinHistory: builder.mutation<any, any>({
			query: (selectedData) => {
				console.log(selectedData, "manage the contractor history");

				return {
					url: `${lhwpath}/global/orders/contractor/coin/history`,
					method: "POST",
					body: selectedData,
				};
			},
		}),
		getTurnOverData: builder.query<any, any>({
			query: () => {
				return {
					url: `${lhwpath}/global/orders/turnover/data`,
					method: "GET",
				};
			},
		}),
		
	}),
});



// event bubbling & capturinf

export const {
	useOrderTrackerMutation,
	useGetReferralOrdersMutation,
	usePostOrderHistoryMutation,
	useGetOrderDetailsMutation,
	useGetAdminContractorHistoryMutation,
	useGetOrdersDetailsMutation,
	useGetAdminSingleContractorMessageMutation,
	useGetAdminAllContractorMessageQuery,
	useGetDeleteAdminContractorQuery,
	useGetUnblockAdminContractorMutation,
	useGetContractorCoinHistoryMutation,
	useGetTurnOverDataQuery,
} = OrderQuery;
