import { useEffect, useState } from "react";
import logo from "../../assets/logo.svg";
import logo1 from "../../assets/logo1.png";
import chawlaLogo from "../../assets/chawlaLogo.png";
import ChawlaIspat from "../../assets/CHAWLA ISPAT.svg";
import IronJunction from "../../assets/THE IRON JUNCTION.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import { MobileFilter } from "./sidebar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { BiSolidCoinStack } from "react-icons/bi";
import { useLogoutApiMutation } from "../../Redux/Api/Contractor";
import { LoginResponse, SignupWait } from "../../Redux/reducer/LoginReducer";
import { CgProfile } from "react-icons/cg";
import { LiaCoinsSolid } from "react-icons/lia";
import { TbLogout } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import Dropdown from "./Dropdown";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const [LogoutCall, { data: logoutData }] = useLogoutApiMutation();
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const handleLogout = async () => {
    const logoutData = await LogoutCall();
    dispatch(LoginResponse(null));

    closeMenu();
  };

  useEffect(() => {
    if (logoutData && logoutData?.success) {
      dispatch(LoginResponse(null));
    }
  }, [logoutData, dispatch]);

  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const closeSideBar = (data: any) => {
    setIsMenuOpen(data);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const menu = document.getElementById("mobile-menu");
      const hamburgerIcon = document.getElementById("hamburger-icon");

      if (
        isMenuOpen &&
        menu &&
        !menu.contains(event.target as Node) &&
        hamburgerIcon &&
        !hamburgerIcon.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    setDropdown(false);
  }, [location.pathname]);

  const closeMenuForSignUp = () => {
    dispatch(SignupWait(false));
    setIsMenuOpen(false);
  };

  const NavigateToProfile = () => {
    navigate("/profile");
    setIsMenuOpen(false);
  };

  return (
    <div className="flex flex-col bg-[#5097A4] px-[20px] py-[10px] sticky top-0 z-50 w-full">
      <div className="flex lg:flex-row justify-between items-center ">
        <div className="flex items-center gap-3 w-[12%]  sm:w-auto ">
          <span id="hamburger-icon" className="text-[24px] w-[30px]  sm:hidden " onClick={toggleMenu}>
            <RxHamburgerMenu />
          </span>
          <div className=" hidden sm:flex sticky top-0 justify-between items-center bg-[#5097A4]">
            <Link to="/" className="">
              <img src={logo} alt="logo" className="h-12 md:hidden " />
            </Link>
            <Link to="/">
              <img
                src={chawlaLogo}
                alt="logo"
                className="h-11 hidden md:block"
              />
            </Link>
            <div>
              <Link to="/" className="flex flex-col gap-[1px]">
                <img src={ChawlaIspat} alt="logo" className="hidden md:block w-[120px]" />
                <div className="w-full h-[0.03rem] bg-black"></div>
                <img src={IronJunction} alt="logo" className="hidden md:block w-[100px]" />
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex bg-white">
          <Dropdown />
        </div>

        <div className=" flex gap-2 items-center  w-[88%] sm:w-auto ">
          <div className=" bg-white flex sm:hidden rounded-full mx-2 w-[85%] ">
            <input type="text" onClick={() => navigate("/searchpage")} placeholder="Search.." className="w-full flex-shrink py-[7px] pl-3 pr-1 rounded-l-full border-none focus:border-none focus:ring-0 outline-none   " />
            <div className="w-10 flex justify-center items-center border-l-[2px]">
              <IoSearchOutline
                className="w-5 h-5 sm:hidden text-black"
                onClick={() => navigate("/searchpage")}
              />
            </div>

          </div>
          <div className=" sm:hidden w-[15%] flex justify-end">
            <img src={logo1} alt="logo" className=" w-11" />
          </div>

          {userDetails && (
            <div className="hidden sm:flex gap-[10px] justify-center items-center relative ">
              {userDetails?.isVerified && (
                <div
                  className="flex border-[3px] bg-gray-200 h-[35px] rounded-[20px] justify-between items-center gap-2 p-1"
                  onClick={(event) => {
                    event.stopPropagation();
                    setDropdown(!dropdown);
                  }}
                >
                  <BiSolidCoinStack className="text-[#FF9900]" />
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.totalReward}
                  </span>
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.name.split(" ")[0].toUpperCase()}
                  </span>
                </div>
              )}
              {userDetails?.isVerified && (
                <div className="ml-[4px] hidden sm:block">
                  <Link to={"/profile"}>

                  {
                    userDetails?.avatar && userDetails?.avatar.length>0 ?
                    <img src={userDetails?.avatar  } alt="profile" className="rounded-full h-[50px] w-[50px]" />
                    :  
                    <div className="rounded-full h-[50px] w-[50px] bg-white text-blue-700 font-bold text-[40px] text-center flex items-center justify-center">
                    {userDetails.name[0].toUpperCase()}
                  </div>
                  }
                  
                  </Link>
                </div>
              )}
              {dropdown && (
                <div
                  id="dropdown"
                  className=" flex flex-col absolute bg-white border rounded-md top-[2.89rem] right-[1rem] overflow-hidden z-50"
                >
                  <div
                    className="px-[12px] py-[8px] hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={() => navigate("/profile/rewards")}
                  >
                    <LiaCoinsSolid className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Get Rewards
                    </span>
                  </div>
                  <div
                    className="px-[12px] py-[8px] hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={handleLogout}
                  >
                    <TbLogout className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Logout
                    </span>
                  </div>
                  <div
                    className="px-[12px] py-[8px] hover:bg-slate-300 flex items-center gap-[10px] w-[200px] border-b"
                    onClick={() => navigate("/profile")}
                  >
                    <CgProfile className="text-[#5097A4]" />
                    <span className="font-[500] text-[16px] leading-[19px]">
                      Profile
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {userDetails?.name && userDetails?.isVerified ? null : (
            <Link to="/signup" onClick={() => dispatch(SignupWait(false))} className=" hidden sm:block">
              <button className="bg-[#FEBD69] font-[600] font-inter md:font-bold whitespace-nowrap flex justify-center items-center md:mb-3 rounded-lg md:py-4 py-2 md:px-[3rem] px-2 my-2 md:text-sm text-[12px] text-[#0F172A] border-2 border-[#FEBD69] h-[40px] leading-[20px] hover:bg-white ml-2 hover:text-[#0F172A] hover:border-[#1E293B] hover:transition hover:duration-500">
                SignUp/Signin
              </button>
            </Link>
          )}
        </div>
      </div>

      {/* mobileMenu */}
      <div className="lg:hidden bg-white">
        <Dropdown />
      </div>

      <div className="relative  ">
        {/* Gradient Background */}
        <div
          id="shadow"
          className={`fixed top-0 right-0 bottom-0 z-40  w-[calc(100%-300px)]  ${isMenuOpen ? "bg-black opacity-50" : "opacity-0 pointer-events-none"
            }`}
          onClick={() => setIsMenuOpen(false)}
        ></div>
        <div
          className={`lg:hidden bg-[#D0F8FF] px-5 fixed left-0 top-0 bottom-0 z-50 w-[300px] transition-all duration-500 ${isMenuOpen
            ? "opacity-100 translate-x-0"
            : "opacity-0 -translate-x-full"
            }`}
        >
          {/* <div className="flex jsu">ss</div> */}
          <div className="flex sticky top-0 justify-between items-center w-full   pt-[12px]">
            {userDetails?.isVerified && (
              <div className=" text-blue-700 font-bold  items-center  w-full mx-1 p-[4px] text-center flex flex-col gap-3 border-b-[1px] border-[#828181]">
                <Link
                  to={'/profile'}
                  className=" rounded-full text-[46px] w-[64px] h-[64px] bg-white flex justify-center items-center"
                  onClick={NavigateToProfile}
                  style={{ boxShadow: '0px 1px 5px 0px #00000040' }}
                >
                  {userDetails.name[0].toUpperCase()}{" "}
                </Link>{" "}
                <Link
                  to={'/profile'}
                  className="font-medium text-[16px]  text-[#000000]"
                  onClick={NavigateToProfile}
                >
                  {userDetails.name}
                </Link>
                <div className="flex bg-white text-black py-2 px-3 rounded-2xl gap-1 mb-1 w-min-[120px] ">
                  <BiSolidCoinStack className="text-[#FF9900]" />
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.totalReward}
                  </span>
                  <span className="font-[600] text-[14px] leading-[17px]">
                    {userDetails.name.split(" ")[0].toUpperCase()}
                  </span>
                </div>
              </div>
            )}
            {!userDetails?.isVerified && (
              <div className=" text-blue-700 font-bold  items-center  w-full mx-1 p-[4px] text-center flex flex-col gap-3 border-b-[1px] border-[#828181]">
                <span
                  className=" rounded-full text-[46px] w-[70px] h-[70px] bg-white flex justify-center items-center"
                  style={{ boxShadow: '0px 1px 5px 0px #00000040' }}
                >

                  <img src={logo1} alt="" className="w-[97%] h-[97%] object-contain p-1" />
                </span>{" "}

                <button onClick={() => {
                  navigate("/signup")
                  closeMenu()
                }} className="text-[14px] font-medium text-black bg-[#FEBD69] px-3 py-[2px] rounded-xl mb-[4px]">Join Now</button>

              </div>
            )}
          </div>

          <div className="flex flex-col h-[80%] w-full bg-[#D0F8FF]  ">
            <div className="h-full no-scrollbar overflow-auto flex-wrap  px-[4px]">
              <div className=" py-3 border-b-[1px] border-[#828181]">
                <MobileFilter closeSideBar={closeSideBar} />
              </div>

              <div className="flex items-center font-semibold text-[15px] border-b-[1px] border-[#828181] leading-[24px] pr-3 py-[14px] ">
                <Link to="/" onClick={closeMenu}>
                  Home
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[15px] border-b-[1px] border-[#828181] leading-[24px] pr-3 py-[14px] ">
                <Link to="/items" onClick={closeMenu}>
                  Items
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[15px] border-b-[1px] border-[#828181] leading-[24px] pr-3 py-[14px] ">
                <Link to="/order" onClick={closeMenu}>
                  Order
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[15px] border-b-[1px] border-[#828181] leading-[24px] pr-3 py-[14px] ">
                <Link to="/aboutus" onClick={closeMenu}>
                  About us
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[15px] border-b-[1px] border-[#828181] leading-[24px] pr-3 py-[14px] ">
                <Link to="/contactus" onClick={closeMenu}>
                  Contact Us
                </Link>
              </div>
              {
                userDetails && <div className="flex items-center font-semibold text-[15px] border-b-[1px] border-[#828181] leading-[24px] pr-3 py-[14px] mb-2 ">
                  <Link to="/assignrewards" onClick={closeMenu}>
                    Assigned Rewards

                  </Link>
                </div>
              }
            </div>

            {/* {userDetails && (
              <div className=" w-[90%] m-auto  ">
                <button
                  className="font-semibold text-center items-center gap-2 flex justify-center   m-auto w-full text-[1.1rem] text-white py-[12px] px-[16px] bg-[#5097A4] rounded-[10px] leading-[24px] font-inter "
                  onClick={handleLogout}
                >
                  <MdLogout />
                  <span>Logout</span>
                </button>
              </div>
            )} */}
            {/* {userDetails?.name && userDetails?.isVerified ? null : (
              <Link to="/signup" onClick={closeMenuForSignUp}>
                <button className="bg-[#FEBD69] font-[600] font-inter md:font-bold whitespace-nowrap flex justify-center items-center md:mb-3 rounded-lg md:py-4 py-4 md:px-[3rem] px-2 my-2 md:text-sm text-[20px] text-[#0F172A] border-2 border-[#FEBD69] h-[40px] leading-[20px] hover:bg-white ml-2 hover:text-[#0F172A] hover:border-[#1E293B] hover:transition hover:duration-500 mr-7">
                  SignUp/Signin
                </button>
              </Link>
            )} */}
            {userDetails && (
              <div className=" w-[60%] m-auto  ">
                <button
                  style={{ boxShadow: '0px 1px 5px 0px #00000040' }}
                  className="font-semibold text-center items-center gap-2 flex justify-center   m-auto w-full text-[1rem] text-white py-[12px] px-[5px] bg-[#5097A4] rounded-[10px] leading-[24px] font-inter border-[5px] border-white mb-[2px] "
                  onClick={handleLogout}
                >
                  <MdLogout />
                  <span>Logout</span>
                </button>
              </div>
            )}

            {userDetails?.name && userDetails?.isVerified ? null : (
              <Link to="/signup" onClick={closeMenuForSignUp} className=" sm:block">
                <button style={{ boxShadow: '0px 1px 5px 0px #00000040' }} className="font-semibold text-center items-center gap-2 flex justify-center  px-4  m-auto  text-[1rem] text-white py-[12px]  bg-[#5097A4] rounded-[10px] leading-[24px] font-inter border-[5px] border-white mb-[2px]">
                  SignUp/Signin
                </button>
              </Link>
            )}


          </div>
          {/* <div className="flex sticky top-0 justify-between items-center w-full border-b  py-[12px]">
            {userDetails?.isVerified && (
              <div className="rounded-[74px] bg-white text-blue-700 font-bold  items-center border w-full mx-1 p-[4px] text-center flex gap-3">
                <span
                  className="border border-turkishBlue-default rounded-full text-[20px] w-[32px] h-[32px]"
                  onClick={NavigateToProfile}
                >
                  {userDetails.name[0].toUpperCase()}{" "}
                </span>{" "}
                <span
                  className="font-bold text-[14px] "
                  onClick={NavigateToProfile}
                >
                  {userDetails.name}
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-col h-[80%] w-full  ">
            <div className="h-full bg-white overflow-auto flex-wrap space-y-4">
              <div className="border-b py-2">
                <MobileFilter closeSideBar={closeSideBar} />
              </div>

              <div className="flex items-center font-semibold text-[16px] border-b-[1px] border-[#828181] leading-[24px] pr-3 py-2 ">
                <Link to="/" onClick={closeMenu}>
                  Home
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px] border-b leading-[24px] px-3 py-1">
                <Link to="/items" onClick={closeMenu}>
                  Items
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px]  border-b leading-[24px] px-3 py-1">
                <Link to="/order" onClick={closeMenu}>
                  Order
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px] border-b leading-[24px] px-3 py-1">
                <Link to="/aboutus" onClick={closeMenu}>
                  About us
                </Link>
              </div>
              <div className="flex items-center font-semibold text-[16px] border-b leading-[24px] px-3 py-1">
                <Link to="/contactus" onClick={closeMenu}>
                  Contact Us
                </Link>
              </div>
              {
                userDetails && <div className="flex items-center font-semibold text-[16px] leading-[24px] px-3">
                  <Link to="/profile/rewards" onClick={closeMenu}>
                    Rewards
                  </Link>
                </div>
              }
            </div>

            {userDetails && (
              <div className=" w-[90%] m-auto  ">
                <button
                  className="font-semibold text-center items-center gap-2 flex justify-center   m-auto w-full text-[1.1rem] text-white py-[12px] px-[16px] bg-[#5097A4] rounded-[10px] leading-[24px] font-inter "
                  onClick={handleLogout}
                >
                  <MdLogout />
                  <span>Logout</span>
                </button>
              </div>
            )}

            {userDetails?.name && userDetails?.isVerified ? null : (
              <Link to="/signup" onClick={closeMenuForSignUp}>
                <button className="bg-[#FEBD69] font-[600] font-inter md:font-bold whitespace-nowrap flex justify-center items-center md:mb-3 rounded-lg md:py-4 py-4 md:px-[3rem] px-2 my-2 md:text-sm text-[20px] text-[#0F172A] border-2 border-[#FEBD69] h-[40px] leading-[20px] hover:bg-white ml-2 hover:text-[#0F172A] hover:border-[#1E293B] hover:transition hover:duration-500 mr-7">
                  SignUp/Signin
                </button>
              </Link>
            )}
          </div> */}
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed top-0 right-5 p-4 z-50 w-[calc(100%-300px)] cursor-pointer ">
          <div className="flex justify-end p-4  rounded-full ">
            <RxCross1
              className="text-[28px] text-white cursor-pointer"
              onClick={closeMenu}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
