import {
  AllCategoriesResponse,
  CategoryProductsResponse,
  CategoryProductsResponse2,
  RewardResponse,
} from "../../types/api-types";
import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";

interface categoryPrice {
  category: string;
}

export const categoryProductAPI: any = api.injectEndpoints({
  endpoints: (builder) => ({
    categoryProductAPI: builder.query<CategoryProductsResponse, string>({
      query: () => {
        return {
          url: `${lhwpath}/global/getCategories/publish`,
          method: "GET",
        };
      },
    }),

    particularCategory: builder.query<CategoryProductsResponse, string>({
      query: (name) => {
        return {
          url: `${lhwpath}/global/searchData/${name}`,
          method: "GET",
        };
      },
    }),

    categoryProductAPI2: builder.query<CategoryProductsResponse2, string>({
      query: () => {
        // console.log("I m cat2");
        return {
          url: `${lhwpath}/global/getCategories/publish`,
          method: "GET",
        };
      },
    }),

    particularCategory2: builder.query<CategoryProductsResponse, string>({
      query: (name2) => {
        // console.log(name2, 'vgvtg');
        return {
          url: `${lhwpath}/global/searchData/${name2}`,
          method: "GET",
        };
      },
    }),

    allCategories: builder.query<AllCategoriesResponse, any>({
      query: () => {
        return {
          url: `${lhwpath}/global/getCategories/publish`,
          method: "GET",
        };
      },
    }),

    getAdminCategoryProducts: builder.query<
      any,
      { name: string; search?: string }
    >({
      query: ({ name, search = "" }) => {
        const constructUrl = (name: string, search?: string): string => {
          const encodedName = encodeURIComponent(name);
          const params = new URLSearchParams();
          if (search) params.append("search", search);
          const url = params.toString()
            ? `/global/customer/admin/searchData/${encodedName}/?${params.toString()}`
            : `/global/customer/admin/searchData/${encodedName}`;
          // console.log("Constructed URL:", url); // Log the URL
          return url;
        };

        // console.log("i m ", name);
        return {
          url: constructUrl(name, search),
          method: "GET",
        };
      },

      //  providesTags: ["category"] ,   // Specify cache tags to invalidate
    }),

    adminCategories: builder.query<
      any,
      { page?: number; limit?: string; search?: string }
    >({
      query: ({ page, limit, search = "" }) => {
        const constructUrl = (
          page?: number,
          limit?: string,
          search?: string
        ): string => {
          const params = new URLSearchParams();
          if (page) params.append("page", page.toString());
          if (limit && limit !== "All") params.append("limit", limit);
          if (search) params.append("search", search);

          return params.toString()
            ? `${lhwpath}/global/customer/admin/getCategories/?${params.toString()}`
            : `${lhwpath}/global/customer/admin/getCategories/`;
        };

        return {
          url: constructUrl(page, limit, search),
          method: "GET",
        };
      },
      // invalidatesTags: ["category"],
    }),

    getCategoryReward: builder.mutation<
      RewardResponse,
      { id: string; body: any }
    >({
      query: ({ id, body }) => {
        // console.log("id,reward",{id,body})
        return {
          url: `${lhwpath}/global/rewards/add/category/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    categoryReward: builder.query<any, { id: any }>({
      query: (id) => {
        return {
          url: `${lhwpath}/global/rewards/category/${id}`,
          method: "GET",
        };
      },
    }),
    itemReward: builder.query<any, { id: any }>({
      query: (id) => {
        return {
          url: `${lhwpath}/global/category/item/${id}`,
          method: "GET",
        };
      },
    }),

    updatedItemReward: builder.mutation<
      RewardResponse,
      { id: string; body: any }
    >({
      query: ({ id, body }) => {
        // console.log("id,reward",{id,body})
        return {
          url: `${lhwpath}/global/rewards/add/item/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getCategoryPrice: builder.mutation<
      RewardResponse,
      { categoryPrice: categoryPrice }
    >({
      query: ({ categoryPrice }) => {
        // console.log("id,reward",{id,body})
        return {
          url: `/global/getCategoryPrice`,
          method: "POST",
          body: categoryPrice,
        };
      },
    }),
    getCarouselData: builder.mutation<RewardResponse, { categoryPrice: any }>({
      query: (category) => {
        return {
          url: `/global/getCategories/carousel/data`,
          method: "POST",
          body: category,
        };
      },
    }),

    navbarData: builder.query<any, any>({
      query: () => {
        return {
          url: `${lhwpath}/global/data/count`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useCategoryProductAPIQuery,
  useParticularCategoryQuery,
  useCategoryProductAPI2Query,
  useParticularCategory2Query,
  useAllCategoriesQuery,
  useGetAdminCategoryProductsQuery,
  useAdminCategoriesQuery,
  useGetCategoryRewardMutation,
  useCategoryRewardQuery,
  useItemRewardQuery,
  useUpdatedItemRewardMutation,
  useGetCategoryPriceMutation,
  useGetCarouselDataMutation,
  useNavbarDataQuery,
} = categoryProductAPI;
