import { BsFillHandbagFill } from "react-icons/bs";
import bigcircle from "../../assets/CIRCLE.svg";
import { useGetCategoryPriceMutation } from "../../Redux/Api/Category";
import { useEffect, useState } from "react";
import PriceSection from "./PriceSection";
// import lineImg from "../../assets/Vector 308.png"

interface imageCarouselDetails {
  title: string;
  image: string;
  _id: string;
}

type Category = {
  id: string;
  name: string;
  itemCount: number;
  companyCount: number;
  image: string;
  imageCarouselDetails: imageCarouselDetails[];
  productNames: string[];
};

interface Comp1Props {
  categoryId: Category;
}

interface PriceData {
  priceWithOutMargin: number;
  averageDispatchQuantity3Months: number;
  averageDispatchQuantity6Months: number;
}

const Comp1 = ({ categoryId }: Comp1Props) => {
  const category = categoryId; 
  const [mutation, { data, isLoading }] = useGetCategoryPriceMutation();
  const [categoryPrice, setCategoryPrice] = useState({
    category: "",
  });

  useEffect(() => {
    if (category?.id) {
      setCategoryPrice((prevState) => ({
        ...prevState,
        category: category?.id,
      }));
    }
  }, [category]);

  useEffect(() => {
    if (categoryPrice.category) {
      mutation({ categoryPrice })
        .then((response: PriceData) => { })
        .catch((error: any) => {
          // console.error('Mutation error:', error);
        });
    }
  }, [categoryPrice, mutation]);

  return (
    <>
      <div
        className="flex  flex-col sm:flex-row overflow-hidden sm:justify-between relative left-0 h-[40rem] sm:h-[24rem] md:h-[28rem] lg:h-[35rem]  md:gap-[7rem] 
      bg-[#E2E8F0]">
        <div className="flex flex-col px-[1rem]  my-[1.365rem]  md:my-[2.365rem] lg:mx-[3.5rem] xl:mx-[5.5rem] lg:my-[5.365rem] gap-[0.26rem]  md:gap-[2rem] pt-[2rem] ">
          <div className="font-[400] font-bebas-neue  text-xl sm:text-[1.45rem] md:text-[3rem] xl:text-[5rem] leading-[3.6rem] cursor-pointer relative z-30">
            {category?.name}
          </div>
          <div className="flex flex-row gap-[0.375rem] md:gap-[1.375rem] ">
            <PriceSection data={data} isLoading={isLoading} />
          </div>
          <div className=" w-[14.5rem] md:w-[17.5rem] h-[3.375rem] border-[0.12rem]  rounded-[0.25rem] flex flex-row justify-center items-center gap-[0.625rem] mt-[0.875rem] cursor-pointer relative z-30 lg:py-3 border-black">
            <BsFillHandbagFill />
            <span>Continue for Better Pricing</span>
          </div>
        </div>

        <div className=" w-full relative flex ">
          <div className=" relative -left-[30px] md:-left-[160px] xl:-left-[100px] max-w-[323px]   z-40 flex  flex-col justify-center h-fit  gap-1 items-center border-[0.125rem] rounded-[0.750rem] px-[2rem]    py-[1rem]  sm:mt-[3rem] ml-[4.34rem] sm:ml-[0.375rem]  border-black ">
            <span className=" flex font-[500] text-[16px] md:text-[1.375rem] leading-[1rem] text-black">
              Available in{" "}
            </span>
            <span className="font-[500] flex flex-wrap gap-1 text-[14px] md:text-[1rem] leading-[1.1rem] text-[#5C5C77] max-w-[319px] ">
              {category?.productNames?.map((details, index) => (
                <span key={index} className="whitespace-nowrap">
                  {details}
                  {index !== details?.length - 1 && ","}
                </span>
              ))}
              {"And More"}
            </span>
          </div>

          <div className="absolute  bottom-[-120px] sm:bottom-3 left-[11.23rem] md:left-[-1rem] md:bottom-4  xl:left-[6rem] 2xl:left-[10rem]  justify-between h-full  w-[15rem] md:w-[25rem]  lg:w-[25rem] sm:h-[21rem] lg:h-[30rem]">
            <div className="relative  sm:mt-10 md:mt-2 lg:mt-10 z-0 ">
              {isLoading ? (
                <div className="animate-pulse absolute top-[1rem] md:top-[6rem] right-0 lg:top-[8rem]  md:right-[70px] z-10 w-[250px] flex ">
                  <div className="bg-gray-300 h-[200px] w-[200px] md:h-[250px] md:w-[250px] rounded-full p-2 flex justify-center items-center">
                    <div className="bg-gray-200 w-[150px] h-[150px] md:w-[150px] rounded-full"></div>
                  </div>
                </div>
              ) : (
                <img
                  src={
                    category?.imageCarouselDetails?.[0]
                      ?.image
                  }
                  alt={
                    category?.imageCarouselDetails?.[0]
                      ?.title
                  }
                  className="absolute top-[2rem] md:top-[5.67rem]  right-5  z-10 w-full object-cover"
                />
              )}
              <img
                src={bigcircle}
                className="w-[21rem] lg:w-[25rem] absolute top-0 right-5 md:top-14 md:right-0  "
                alt="bigcircle"
              />
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-b  from-[#E2E8F0] to-white pointer-events-none z-30">
          <div className="w-full h-full backdrop-blur-[2px]"></div>
        </div>
      </div>
    </>
  );
};

export default Comp1;
