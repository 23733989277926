import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";
import {
  Contractor,
  ContractorResponse,
  approveContractorResponse,
} from "./types";

export const ContractorQuery: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractor: builder.query<ContractorResponse, string>({
      query: (id) => ({
        url: `${lhwpath}/global/contractor/details/${id}`,
        method: "GET",
      }),
      providesTags: ["Contractor"],
    }),

    // Define a mutation for fetching a list of verified contractors
    publishedContractor: builder.mutation<Contractor, { limit: string | number; page: number; name?: string, city?: string, grade?: string }>({
      query: ({ limit, page, name = "", city = "", grade = "" }) => {
        // Ensure `page` is a valid number
        const pageNumber = Number.isInteger(page) && page > 0 ? page : 1;

        // Convert `limit` to number if it's a string
        let limitValue: number | undefined;
        if (typeof limit === "string") {
          limitValue = limit === "All" ? undefined : parseInt(limit, 10);
        } else if (typeof limit === "number") {
          limitValue = limit;
        }

        // Construct the URL with parameters
        const constructUrl = (
          page: number,
          limit?: number,
          name?: string,
          city?: string,
          grade? : string
        ): string => {
          const params = new URLSearchParams();
          params.append("page", page.toString());
          if (limit !== undefined) {
            params.append("limit", limit.toString());
          }
          if (name) {
            params.append("name", name);
          }
          if (city) {
            params.append("city", city);
          }
          if (grade) {
            params.append('grade', grade);
          }
          return params.toString()
            ? `/global/contractor/all/verified?${params.toString()}`
            : `/global/contractor/all/verified`;
        };

        return {
          url: constructUrl(pageNumber, limitValue, name, city, grade),
          method: "GET",
        };
      },
      invalidatesTags: ["Contractor"],
    }),
    // publishedContractor: builder.mutation<  Contractor, { limit: string | number; page: number; search?: string }>({
    //   query: ({ limit, page, search = "" }) => {
    //     // Ensure `page` is a valid number
    //     const pageNumber = Number.isInteger(page) && page > 0 ? page : 1;

    //     // Convert `limit` to number if it's a string
    //     let limitValue: number | undefined;
    //     if (typeof limit === "string") {
    //       limitValue = limit === "All" ? undefined : parseInt(limit, 10);
    //     } else if (typeof limit === "number") {
    //       limitValue = limit;
    //     }

    //     // Construct the URL with parameters
    //     const constructUrl = (
    //       page: number,
    //       limit?: number,
    //       search?: string
    //     ): string => {
    //       const params = new URLSearchParams();
    //       params.append("page", page.toString());
    //       if (limit !== undefined) {
    //         params.append("limit", limit.toString());
    //       }
    //       if (search) {
    //         params.append("search", search);
    //       }
    //       return params.toString()
    //         ? `/global/contractor/all/verified?${params.toString()}`
    //         : `/global/contractor/all/verified`;
    //     };

    //     return {
    //       url: constructUrl(pageNumber, limitValue, search),
    //       method: "GET",
    //     };
    //   },
    //   invalidatesTags: ["Contractor"],
    // }),

    unpublishedContractor: builder.query<Contractor[], { limit: string; page: number; search?: string }>({
      query: ({ limit, page, search = "" }) => {
        const constructUrl = (
          page?: number,
          limit?: string | number,
          search?: string
        ): string => {
          const params = new URLSearchParams();
          if (page) params.append("page", page.toString());
          if (limit && limit !== "All") params.append("limit", limit.toString());
          if (search) params.append("search", search);

          return params.toString()
            ? `/global/contractor/all/unverified?${params.toString()}`
            : `/global/contractor/all/unverified`;
        };

        return {
          url: constructUrl(page, limit, search),
          method: "GET",
        };
      },
      // providesTags: ['Contractor'],
    }),

    toVerifyContractor: builder.mutation<any, string>({
      query: (id) => ({
        url: `${lhwpath}/global/contractor/verify/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Contractor"],
    }),

    getProfile: builder.query<Contractor, string>({
      query: () => ({
        url: `${lhwpath}/global/contractor/getprofile`,
        method: "GET",
      }),

      providesTags: ['Contractor'],
    }),

    logoutApi: builder.mutation<any, string>({
      query: (id) => ({
        url: `${lhwpath}/global/contractor/logout`,
        method: "POST",
      }),
      invalidatesTags: ["Contractor"],
    }),

    approveContractor: builder.mutation<
      approveContractorResponse,
      { id: string; body: any }
    >({
      query: ({ id, body }) => ({
        url: `/global/contractor/add/categories/grade/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Contractor"],
    }),

    changeGrade: builder.mutation<
      approveContractorResponse,
      { id: string; body: any }
    >({
      query: ({ id, body }) => {
        // console.log(id, body);
        return {
          url: `/global/contractor/grade/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Contractor"],
    }),

    changeCategories: builder.mutation<
      approveContractorResponse,
      { id: string; body: any }
    >({
      query: ({ id, body }) => {
        // console.log(id, body);
        return {
          url: `/global/contractor/add/categories/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Contractor"],
    }),

    addCoins: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => {
        // console.log(id, body);
        return {
          url: `/global/contractor/add/coins/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Contractor"],
    }),
    removeCoins: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => {
        // console.log(id, body);
        return {
          url: `/global/contractor/remove/coins/${id}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Contractor"],
    }),

    changePassword: builder.mutation<any, any>({
      query: ({ body, id }) => {
        // console.log("order body", body);  // This should log the body received by the query function
        return {
          url: `${lhwpath}/global/contractor/admin/changepassword/${id}`,
          method: "POST",
          body,
        };
      },
    }),
    contractorChangePassword: builder.mutation<any, any>({
      query: (body) => {
        console.log("contractor passord body", body);  // This should log the body received by the query function
        return {
          url: `${lhwpath}/global/contractor/changepassword`,
          method: "POST",
          body,
        };
      },
    }),

    getAllContractor: builder.query<any, any>({
      query: () => {
        // console.log("order body", body);  // This should log the body received by the query function
        return {
          url: `/global/contractor/data`,
          method: "GET",
        };
      },
      providesTags: ['Contractor'],
    }),

    getContractorCategory: builder.query<any, any>({
      query: () => {

        return {
          url: `/global/contractor/get/categories`,
          method: "GET",
        };
      },
      // providesTags: ['Contractor'],
    }),

    deleteContractor: builder.mutation<any, any>({
      query: ({ id, remark }) => {

        console.log(id, remark)
        return {
          url: `/global/contractor/${id}`,
          method: "DELETE",
          body: { remark }
        };
      },
      invalidatesTags: ["Contractor"],
    }),

    postMessage: builder.mutation<any, any>({
      query: (formData) => {
        return {
          url: `/global/contractor/message`,
          method: "POST",
          body: formData
        };
      },
      // invalidatesTags: ["Contractor"],
    }),

    contractor: builder.mutation<any, any>({
      query: (data) => {
        // const isFormData = formData instanceof FormData;
        console.log(data, "this query api data")
        const headers: Record<string, string> = {};


        headers['Content-Type'] = 'multipart/form-data';

        return {
          url: `/global/contractor/query`,
          method: "POST",
          body: data
        };
      },

    }),
    changeEmailAndName: builder.mutation<any, any>({
      query: (data) => {
        console.log("change email and name", data)

        return {
          url: `/global/contractor/changenameandemail`,
          method: "PATCH",
          body: data
        };
      },

      invalidatesTags: ["Contractor"],

    }),

    dashboardWebsiteVist: builder.mutation<any, any>({
      query: (data) => {
   
        return {
          url: `${lhwpath}/global/dashboard/websiteuser`,
          method: "POST",
          body:data
        };
      },
      
    }),

    contractorAvtar: builder.mutation<any, string>({
      query: (data) => ({
        url: `${lhwpath}/global/contractor/avatar`,
        method: "PATCH",
        body:data
      }),
      invalidatesTags: ["Contractor"],
    }),
    
  }),
});

export const {
  useChangeGradeMutation,
  useChangeCategoriesMutation,
  useGetContractorQuery,
  usePublishedContractorMutation,
  useUnpublishedContractorQuery,
  useToVerifyContractorMutation,
  useGetProfileQuery,
  useLogoutApiMutation,
  useApproveContractorMutation,
  useAddCoinsMutation,
  useRemoveCoinsMutation,
  useChangePasswordMutation,
  useContractorChangePasswordMutation,
  useGetAllContractorQuery,
  useDeleteContractorMutation,
  usePostMessageMutation,
  useContractorMutation,
  useGetContractorCategoryQuery,
  useChangeEmailAndNameMutation,
  useDashboardWebsiteVistMutation,
  useContractorAvtarMutation
} = ContractorQuery;
