import logo from "../assets/footerLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { RiArrowUpSFill } from "react-icons/ri";
import usePageView from "../Redux/PageView/usePageView";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookSquare } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";

const Footer = () => {
  const navigate = useNavigate();
  const openGmail = () => {
    window.open(
      "https://mail.google.com/mail/u/0/#inbox?compose=new",
      "_blank"
    );
  };

  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const NavigateToInstagram = () => {
    window.open("https://www.instagram.com/chawlaispat?igsh=dXozbHNueXk0cGRr");
  };

  const NavigateToFacebook = () => {
    window.open("https://www.facebook.com/chawlaispatrdp");
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleWhatsappClick = () => {
    const phoneNumber = "";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  // const [language, setLanguage] = useState("en");

  // const toggleLanguage = () => {
  //   const newLanguage = language === 'en' ? 'hi' : 'en';
  //   setLanguage(newLanguage);

  //   const selectElement = document.querySelector('.goog-te-combo') as HTMLSelectElement;
  //   if (selectElement) {
  //     selectElement.value = newLanguage;
  //     selectElement.dispatchEvent(new Event('change'));
  //   }
  // };

  const views = usePageView();
  const viewDigits = views.toString().padStart(6, "0").split("");

  return (
    <>
      <div
        className="h-[44px] bg-[#68C2D3] flex flex-col justify-center items-center md:hidden text-white"
        onClick={scrollToTop}
      >
        <RiArrowUpSFill className="w-[20px] h-[20px]" />
        <h2>Top of page</h2>
      </div>
      <div id="google_translate_element" style={{ display: "none" }}></div>

      <div className="sm:px-[70px] pt-[16px] sm:pb-[16px] bg-[#5097A4] overflow-hidden flex flex-col gap-[10px]">
        <div className="flex justify-end "></div>
        <div className="flex flex-row md:flex-row gap-[14px] sm:gap-[20px] md:gap-10 justify-between bg-[#5097A4] px-[36px] py-[60px] sm:px-[10px] sm:py-[8px]">
          <div>
            <h2 className="text-[#FFFFFF] pb-[19px] font-[700] text-[14px] leading-[19.6px] tracking-[0.125rem] font-sans">
              QUICK LINKS
            </h2>
            <div className="flex flex-col font-[400] text-[14px] leading-[20px] text-[#FFF]">
              <Link
                to="/aboutus"
                className="hover:underline cursor-pointer mb-2 flex flex-nowrap"
              >
                Why Lohawalla?
              </Link>
              <Link to="/items" className="hover:underline cursor-pointer mb-2">
                Our products
              </Link>
              <Link
                to="/contactus"
                className="hover:underline cursor-pointer mb-2"
                onClick={handleWhatsappClick}
              >
                Chat with us
              </Link>
              <Link
                to="/signup"
                className="hover:underline cursor-pointer mb-2"
              >
                Register
              </Link>
              <Link to="/faq" className="hover:underline cursor-pointer sm:hidden">
                Faq
              </Link>
            </div>
          </div>

          <div className="flex flex-col">
            <h2 className="text-[#FFFFFF] pb-[19px] font-[700] text-[14px] leading-[19.6px] tracking-[0.125rem] font-sans">
              RESOURCES
            </h2>
            <div className="flex flex-col justify-between gap-4 font-[400] text-[14px] leading-[20px] text-[#FFF]">
              <Link to="/">
                <span className="hover:underline">Home</span>
              </Link>
              <Link to="/categories">
                <span className="cursor-pointer hover:underline">
                  Categories
                </span>
              </Link>
              <Link to="/items">
                <span className="cursor-pointer hover:underline">Products</span>
              </Link>
              <Link to="/aboutus">
                <span className="cursor-pointer hover:underline">About us</span>
              </Link>
              <Link to="/contactus">
                <span className="cursor-pointer hover:underline">
                  Contact us
                </span>
              </Link>
            </div>
          </div>

          <div className="md:flex flex-col hidden sm:flex">
            <h2 className="text-[#FFFFFF] pb-[19px] font-[700] text-[14px] leading-[19.6px] tracking-[0.125rem] font-sans">
              COMPANY
            </h2>
            <div className="flex flex-col justify-between h-[54px] gap-3 font-[400] text-[14px] leading-[20px] text-[#FFF]">
              <Link to="/aboutus">
                <span className="cursor-pointer hover:underline">About us</span>
              </Link>
              <span className="cursor-pointer hover:underline">Careers</span>
              <span className="cursor-pointer hover:underline hidden sm:block" onClick={() => navigate("/faq")}>Faqs</span>
            </div>
          </div>

          {/* <GoogleTranslate /> */}

          <div className="md:flex flex-col hidden sm:flex">
            <h2 className="text-[#FFFFFF] pb-[19px] font-[700] text-[14px] leading-[19.6px] tracking-[0.125rem] font-sans">
              CONTACT US
            </h2>
            <div className="flex flex-col justify-between font-[400] text-[14px] leading-[20px] gap-2  text-[#FFF]">
              <span
                className="cursor-pointer hover:underline"
                onClick={openGmail}
              >
                contact@lohawalla.com
              </span>

              {/* <TranslateButton /> */}
            </div>
          </div>
        </div>

        <div className="h-full px-[30px] md:h-auto flex flex-col md:flex-row justify-between items-center bg-[#35666F] sm:bg-transparent py-[5px]">
          <div className="flex flex-col md:flex-row h-full gap-[12px] md:gap-[40px] items-center">
            {/* <Link
              to="/"
              className="border border-none w-full flex md:justify-between"
            >
              <img src={logo} className="w-[50px] h-12" alt="Lohawalla Logo" />
            </Link> */}
            <div className="flex flex-col items-center gap-[24px] md:gap-5 whitespace-nowrap md:flex-row font-[400] text-[12px] leading-[21.6px] text-[#FFFFFF] w-full font-inter">
              <h3 className="cursor-pointer">
                © 2024 Chawla Ispat. All rights reserved.
              </h3>
              <span className="cursor-pointer">Terms of Service</span>
              <span className="cursor-pointer">Privacy Policy</span>
              {userDetails && (
                <Link to={"/delete-account"} className="cursor-pointer">
                  Delete Account
                </Link>
              )}
            </div>
          </div>

          <div className="flex flex-row items-center gap-4 my-4 md:my-0">
            <div className="hidden md:flex w-[150px] h-[40px]"></div>
            <div className="flex gap-[20px] text-[#fff]">
              <div className="flex space-x-1 ">
                <span className="text-[15px] leading-[21.6px] font-[400]">
                  Number of all Visitors
                </span>
                {viewDigits.map((digit, index) => (
                  <div
                    key={index}
                    className="w-5 h-6 flex items-center justify-center text-black text-sm font-[500] text-[20px] font-mono rounded-md shadow-md"
                    style={{
                      background:
                        "linear-gradient(to bottom, white 50%, #7DD9FF 50%)",
                    }}
                  >
                    {digit}
                  </div>
                ))}
              </div>
              <FaFacebookSquare
                className="w-[24px] h-[24px] cursor-pointer"
                onClick={NavigateToFacebook}
              />
              <GrInstagram
                className="w-[24px] h-[24px] cursor-pointer"
                onClick={NavigateToInstagram}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
