import img1 from "../../assets/homeImageSection1.jpeg"
import img2 from "../../assets/homeImageSection2.jpeg"
const HomeImageSection = () => {
    return (
        <div className="w-[95%] h-auto mx-auto">
            <h1 className="text-xl md:text-3xl font-semibold text-center p-2 md:p-0 md:my-4">
                Strengthening Your Build with Quality Steel <br /> &
                Construction Solutions!
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3 md:mt-6 p-5 md:p-8 bg-[#5097A426] rounded-2xl">
                <div className="grid gird-cols-1 md:grid-cols-2 gap-6 col-span-2 p-2 md:p-0">
                    <img
                        className=" w-full rounded-2xl h-[320px]  lg:h-[350px]  md:object-cover hidden md:block"
                        src={img1}
                        alt=""
                    />
                    <img
                        className=" w-full rounded-2xl h-[320px] lg:h-[350px]  object-cover"
                        src={img2}
                        alt=""
                    />
                </div>

                <div className="col-span-2 flex items-center    rounded-lg mt-2 p-2 md:p-0">
                    <p className="text-lg font-medium text-justify md:text-center">
                        Founded in 2002 as Chawla Enterprises, our journey began
                        with a passion for excellence and a vision to become a
                        trusted name in the steel industry. In 2007, we
                        rebranded as Chawla Ispat, marking a new chapter in our
                        commitment to providing high-quality steel and
                        construction materials to businesses across Rudrapur and
                        beyond.
                        <br />
                        <br />
                        With over two decades of experience, Chawla Ispat has grown into a reliable supplier of steel products and construction materials, known for delivering exceptional quality and service. Our comprehensive range of products includes everything from steel beams and rods to a variety of construction essentials, catering to the needs of builders, contractors, and industrial projects.
                        <br />
                        <br />
                        At Chawla Ispat, we take pride in building long-lasting relationships with our clients by offering personalized solutions, timely deliveries, and competitive pricing. Our team is dedicated to ensuring that each customer receives the best materials for their projects, no matter the size or scope.
                         <br />
                         <br />
                         Driven by integrity and a commitment to innovation, we continue to be a leading steel supplier and construction material provider in the region, helping build the future, one project at a time.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HomeImageSection;
