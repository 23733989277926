import { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { CiMedicalClipboard } from "react-icons/ci";
import { FaHourglassHalf } from "react-icons/fa6";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { MdOutlineLogout } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useContractorAvtarMutation, useLogoutApiMutation } from "../../../Redux/Api/Contractor";
import { LoginResponse } from "../../../Redux/reducer/LoginReducer";
import Profile from "../../../assets/profile.jpg";
import { useCreateImageURLMutation } from "../../../Redux/Api/Admin/Image";
import { AiOutlineEdit } from "react-icons/ai";

export const ProfileSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false); 
  const [sidebar, setSidebar] = useState<string>(location.pathname);
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const [getImageURL, { data }] = useCreateImageURLMutation();
  const [ mutateImage] = useContractorAvtarMutation()

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0] || null;

    if (file) {
      setUploadedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      const body = new FormData();
      body.append("image", file);

      try {
        await getImageURL(body).unwrap();
        setShowPopup(true); 
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    if (data && data[0]) {
      console.log("data", data);
    }
  }, [data]);

  useEffect(() => {
    setSidebar(location.pathname);
  }, [location.pathname]);

  const handleRouting = (pathname: string) => {
    navigate(pathname);
    setSidebar(pathname);
  };

  const [LogoutCall, { data: logoutData }] = useLogoutApiMutation();

  const handleLogout = async () => {
    const result = await LogoutCall().unwrap();
    if (result?.success) {
      dispatch(LoginResponse(null));
      navigate("/login"); 
    }
  };

  useEffect(() => {
    if (logoutData && logoutData.success) {
      dispatch(LoginResponse(null));
      navigate("/login"); 
    }
  }, [logoutData, dispatch, navigate]);

  const handleClosePopup = () => {
    setShowPopup(false);
    setUploadedFile(null);
    setImagePreview(null);
  };

  const handleSubmit = () => {
    mutateImage({image:data[0]});
    handleClosePopup();
  };

  return (
    <div className="px-[12px] lg:flex flex-col justify-between border-r w-[266px] h-[80vh] pb-[10px] hidden">
      <div className="flex flex-col gap-[10px] py-[12px]">
        <div className="py-[10px] px-[8px] gap-[10px] flex items-center border-b">
          {userDetails?.isVerified && (
            <div className=" relative ml-[4px] hidden sm:block">
              <label htmlFor="profile-upload">
                <input
                  id="profile-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <div className="w-12 h-10 border-2 border-blue-700 rounded-full bg-white text-turkishBlue-dark font-bold text-xl text-center flex items-center  justify-center">
                  <img src={userDetails?.avatar && userDetails?.avatar.length>0 ? userDetails?.avatar: Profile  } alt="profile" className="rounded-full h-[50px] w-[70px]" />

                  
                </div>
                <div className="absolute bottom-0 -right-[10px] rounded-full p-1 bg-gray-300" ><AiOutlineEdit /></div>
              </label>
            </div>
          )}
          <span className="font-[700] text-[16px] leading-[20px]">
            {userDetails?.name}
          </span>
        </div>

        <div className="text-[#6B7280] space-y-2">
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 ${
              sidebar === "/profile" ? "bg-[#E2E8F0] rounded-[8px]" : ""
            }`}
            onClick={() => handleRouting("/profile")}
          >
            <CgProfile className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              MyProfile
            </span>
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 ${
              sidebar === "/profile/rewards" ? "bg-[#E2E8F0] " : ""
            }`}
            onClick={() => handleRouting("/profile/rewards")}
          >
            <TbMessageCircleQuestion className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              Reward Cards
            </span>
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 ${
              sidebar === "/profile/entry" ? "bg-[#E2E8F0] " : ""
            }`}
            onClick={() => handleRouting("/profile/entry")}
          >
            <CiMedicalClipboard className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              Customer Entry
            </span>
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 ${
              sidebar === "/profile/history" ? "bg-[#E2E8F0]" : ""
            }`}
            onClick={() => handleRouting("/profile/history")}
          >
            <FaHourglassHalf className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              History
            </span>
          </div>
          <div
            className={`px-[13px] py-[9px] flex gap-[10px] items-center rounded-[8px] hover:bg-gray-100 ${
              sidebar === "/profile/query" ? "bg-[#E2E8F0] " : ""
            }`}
            onClick={() => handleRouting("/profile/query")}
          >
            <TbMessageCircleQuestion className="cursor-pointer" />
            <span className="font-[400] text-[16px] leading-[19px] cursor-pointer">
              Query
            </span>
          </div>
        </div>
      </div>

      {/* Logout */}
      <div
        className="rounded-[8px] border px-[13px] py-[9px] cursor-pointer flex gap-[10px] items-center text-[#6B7280] bg-slate-50"
        onClick={handleLogout}
      >
        <MdOutlineLogout className="text-black" />
        <span>Logout</span>
      </div>

      {/* Image Upload Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-blue-100 rounded-lg text-center p-8">
            <h2 className="text-lg font-semibold mb-4">Image Preview</h2>
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Uploaded Preview"
                className="w-32 h-32 border-4 border-blue-800 rounded-full mb-4"
              />
            )}
            <div className="flex justify-between gap-3">
            
              <button
                onClick={handleSubmit}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
